import React, {Component} from "react";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";
import styled from "styled-components";
import {media} from "utils/Media";

const Bg = styled.div`
  .jason {
    text-align: center;
    margin: 0 auto;
    .personDetails {
      text-align: center;
    }
    @media ${media.xxl} {
      max-width: 300px;
      margin: 0 auto;
    }
  }
`;
const SubTitle = styled.p`
  width: 100%;
  font-family: ${(props) => props.theme.font.family.boldTall}!important;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.95rem !important;
  strong {
    font-family: ${(props) => props.theme.font.family.boldTall}!important;
    font-weight: 500 !important;
    font-size: 2.5rem !important;
  }
  p {
    font-size: 0.95rem !important;
  }
`;

const Wrapper = styled.div`
  &:not(.cast) {
    .personDetails {
      position: relative;
      z-index: 3;
    }

    p {
      color: ${(props) => props.theme.colors.white};
      font-size: 1rem;
      @media ${media.sm} {
        font-size: 1.25rem;
      }
    }
  }
`;

const CastWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  margin: 1rem auto;
  max-width: 1200px;

  @media ${media.md} {
    margin: 2rem auto;
  }
`;

const CastItem = styled.div`
  width: calc(100% - 2rem);
  margin: 0.5rem auto;

  @media ${media.md} {
    width: calc(50% - 1rem);
    margin: 0.5rem;
  }

  @media ${media.lg} {
    width: calc(25% - 1rem);
    margin: 1.5rem auto;
  }

  .helper {
    text-align: center;
  }

  .personDetails {
    padding: 0 1rem;
    max-width: 300px;
    margin: 0 auto;
    h4 {
      font-family: ${(props) => props.theme.font.family.boldTall}!important;
      font-weight: 500 !important;
      font-size: 1.75rem !important;
    }
    @media ${media.md} {
      max-width: 100%;
      h4 {
        font-size: 1.85rem !important;
      }
    }
  }

  a {
    text-decoration: none !important;
    display: block;
    margin: 0 auto;

    &.no-cursor {
      cursor: default;
    }

    img {
      transition: ${(props) => props.theme.transitionBase};
    }

    &:hover img {
      transition: ${(props) => props.theme.transitionBase};
    }

    * {
      text-decoration: none !important;
    }
  }

  h4 {
    width: 100%;
    color: ${(props) => props.theme.colors.secondary};
    font-family: ${(props) => props.theme.font.family.boldTall} !important;
    text-transform: uppercase;

    &:nth-of-type(2) {
      color: ${(props) => props.theme.colors.primary} !important;
    }
  }
`;

class CastAndCreatives extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        };
    }

    changeToggleModal = (e, person) => {
        if (person.bio === "") {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }

        this.props.toggleModal(e, person);
    };

    render() {
        const items = this.props.data;

        const Item = (props) => {
            const {node} = props;
            const person = node.node;
            //onClick={(e) => this.changeToggleModal(e, person)}
            return (
                <CastItem onClick={(e) => this.changeToggleModal(e, person)}>
                    {/* <a className={`${person.bio === "" && "no-cursor"}`} > */}
                    <div className="helper">
                        <Bg>
                            {person.image && (
                                <div className="imageWrapper">
                                    <GatsbyImage
                                        image={person.image.childImageSharp.gatsbyImageData}
                                        alt={person.name}
                                        title={person.name}
                                        className="mb-2"
                                    />
                                </div>
                            )}

                            <div className="personDetails">
                                <h4 className="mb-0">{person.name}</h4>
                                <h4
                                    className="mb-0"
                                    dangerouslySetInnerHTML={{__html: person.role}}
                                />
                            </div>
                        </Bg>
                    </div>
                    {/* </a> */}
                </CastItem>
            );
        };

        const collapseItems = items.map((node, i) => {
            const id = `${this.props.slug.replace(/\s/g, "")}${i}`;
            return <Item key={id} node={node}/>;
        });

        return (
            <Wrapper className={this.props.classNames}>
                {this.props.title && (
                    <h2 className="text--white text-center ">{this.props.title}</h2>
                )}
                <CastWrapper>{collapseItems}</CastWrapper>
                {/*{this.props.noJason ? (*/}
                {/*    ""*/}
                {/*) : (*/}
                {/*    <CastItem onClick={() => this.props.jasonModal()}>*/}
                {/*        <div>*/}
                {/*            <Bg>*/}
                {/*                <div className="jason">*/}
                {/*                    <SubTitle>*/}
                {/*                        <strong>Special guest star</strong>*/}
                {/*                        <p>at certain performances</p>*/}
                {/*                    </SubTitle>*/}
                {/*                    <div className="imageWrapper">*/}
                {/*                        <GatsbyImage*/}
                {/*                            image={this.props.jasonImage}*/}
                {/*                            alt="Jason Donavan | Grease The Musical"*/}
                {/*                            className="mb-2"*/}
                {/*                        />*/}
                {/*                    </div>*/}

                {/*                    <div className="personDetails">*/}
                {/*                        <h4 className="mb-0">Jason Donovan</h4>*/}
                {/*                        <h4 className="mb-0">Teen Angel</h4>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </Bg>*/}
                {/*        </div>*/}
                {/*    </CastItem>*/}
                {/*)}*/}
            </Wrapper>
        );
    }
}

export default CastAndCreatives;
