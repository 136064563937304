import React, {Component} from 'react'
import Seo from "utils/Seo"
import styled, {css} from "styled-components"
import {media} from "utils/Media"
import classnames from 'classnames';
import {graphql, StaticQuery} from "gatsby";
import Layout from "../components/Layout/Layout";
import CastAndCreatives from '../components/CastAndCreativePage/CastAndCreatives'
import CastModal from '../components/CastAndCreativePage/CastModal'
import JasonModal from '../components/CastAndCreativePage/jasonModal'
import PageHeader from "../components/shared/PageHeader";
import {
    Container,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';

const NavStyled = styled(Nav)`
    justify-content: center;
    width: 100%;
    text-align: center;
    position: relative;
    transform: none;
    bottom: initial;
    left: initial;
    top: initial;
    background: none;
    z-index: 2;
    border: none !important;
    
    .nav-link {
        line-height: 100%;
        color: ${props => props.theme.colors.white};
        text-decoration: none;
        
        &.active {
            background: none;
            border: none;
            color: ${props => props.theme.colors.yellow};
        }
    }

    li {
        position: relative;
        a {
            cursor: pointer;
            color: ${props => props.theme.colors.yellow};
            text-transform: uppercase;
            margin: 0;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            text-align: center;   
            background: none;
            border: none;
            font-size: 2rem !important;
            text-decoration: none !important;
            text-transform: uppercase;
            color: ${props => props.theme.colors.primary} !important;
            font-family: ${props => props.theme.font.family.boldTall} !important;
            letter-spacing: 0.02em;
    
            @media ${media.md} {
                font-size: 3.5rem !important;
            }
    
            @media ${media.lg} {
                font-size: 5rem !important;
            }
            
            @media ${media.xxl} {
                font-size: 6.25rem !important;
            }
            
            &.active {
                color: ${props => props.theme.colors.secondary} !important;
            }
        }
    }

`
const PageSubHeading = styled.section`
    h2 {
        padding: 2rem 0;
      
        text-align: center;
        font-weight: 500;
        font-family: ${props => props.theme.font.family.boldTall}!important;
        font-size: 2rem!important;
        color: #B71314;
        text-transform: uppercase;
        @media ${media.sm} {
          font-size: 3.5rem!important;
        }
        @media ${media.lg} {
            font-size: 5rem!important;
          
        }
    }
`

class CastAndCreativePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeId: "",
            activeTab: '1',
            modalOpen: false,
            jasonModalOpen: false,
            person: null
        }

    }

    toggle = (tab, e) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    toggleModal = (e, person) => {
        e && e.stopPropagation()
        e && e.preventDefault()
        this.setState({
            modalOpen: !this.state.modalOpen,
            person: person
        })
    }
    toggleJason = () => {
        this.setState({
            jasonModalOpen: !this.state.jasonModalOpen,

        })
    }
    jasonModal = () => {
        this.setState({
            jasonModalOpen: !this.state.jasonModalOpen
        })
    }

    render() {
        return (

            <Layout>
                <Seo
                    slug="/cast-and-creative" title="Cast & Creative"
                />
                <PageHeader tour={true} />
                <NavStyled tabs className="c-collapse-nav pt-4 pt-md-5">
                    <NavItem
                        className={classnames({active: this.state.activeTab === '1'})}
                    >
                        <NavLink
                            className={classnames({active: this.state.activeTab === '1'})}
                            onClick={(e) => {
                                this.toggle('1', e);
                            }}
                        >
                            Cast
                        </NavLink>
                    </NavItem>
                    <NavItem
                        className={classnames({active: this.state.activeTab === '2'})}
                    >
                        <NavLink
                            className={classnames({active: this.state.activeTab === '2'})}
                            onClick={(e) => {
                                this.toggle('2', e);
                            }}
                        >
                            Creatives
                        </NavLink>

                    </NavItem>
                    <div
                        className={`c-collapse-nav nav--scale-bg ${this.state.activeTab === '1' ? 'active' : ''}`}/>
                    <div
                        className={`c-collapse-nav nav--bg ${this.state.activeTab === '2' ? 'active' : ''}`}/>
                </NavStyled>

                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1" key={`cast`}>
                        {/* <CastAndCreatives toggleModal={this.toggleModal} jasonModal={this.jasonModal} classNames="cast" slug="castMain" data={this.props.data.allCastMainJson.edges}  jasonImage={this.props.data.JasonDonavan.childImageSharp.gatsbyImageData}/> */}
                        <CastAndCreatives toggleModal={this.toggleModal} jasonModal={this.jasonModal} classNames="cast" slug="cast" data={this.props.data.allCastJson.edges}  jasonImage={this.props.data.JasonDonavan.childImageSharp.gatsbyImageData}/>
                        {/* <h3 className="special">SPECIAL GUEST STAR</h3>
                        <CastAndCreatives toggleModal={this.toggleModal} jasonModal={this.jasonModal} classNames="cast" slug="cast" data={this.props.data.allJasonJson.edges}  jasonImage={this.props.data.JasonDonavan.childImageSharp.gatsbyImageData}/> */}
                    </TabPane>
                    <TabPane tabId="2" key={`creative`}>
                        <CastAndCreatives toggleModal={this.toggleModal} classNames="creative" slug="creative" noJason data={this.props.data.allCreativeJson.edges}/>
                        {/*<PageSubHeading><h2>Stage Management</h2></PageSubHeading>*/}
                        {/*<CastAndCreatives toggleModal={this.toggleModal} classNames="stage" slug="stage" noJason data={this.props.data.allStageJson.edges}/>*/}
                    </TabPane>
                </TabContent>

                <CastModal isOpen={this.state.modalOpen} toggle={this.toggleModal} person={this.state.person} />
                <JasonModal isOpen={this.state.jasonModalOpen} toggle={this.toggleJason} img={this.props.data.JasonDonavan.childImageSharp.gatsbyImageData} />
            </Layout>
        )
    }
}

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                allCastMainJson {
                    edges {
                        node {
                            id
                            name
                            role
                            bio
                            image {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 360, 
                                        quality: 100
                                    )
                                }
                            }
                        }
                    }
                }
                allJasonJson {
                    edges {
                        node {
                            id
                            name
                            role
                            bio
                            image {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 360, 
                                        quality: 100
                                    )
                                }
                            }
                        }
                    }
                }
                allCastJson {
                    edges {
                        node {
                            id
                            name
                            role
                            bio
                            image {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 360, 
                                        quality: 100
                                    )
                                }
                            }
                        }
                    }
                }
                allCreativeJson {
                    edges {
                        node {
                            id
                            name
                            role
                            bio
                        }
                    }
                }
                allStageJson {
                    edges {
                        node {
                            id
                            name
                            role
                        }
                    }
                }
                JasonDonavan:  file(name: {eq: "Jason-Donovan"}) {
                    childImageSharp {
                                    gatsbyImageData(
                                        width: 360, 
                                        quality: 100
                                    )
                                }
  }
            }
		`}
        render={data => (
            <CastAndCreativePage data={data}/>
        )}
    />
)
export default Query