import React, {Component} from 'react';
import styled from "styled-components"
import {media} from "utils/Media"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import {graphql, StaticQuery} from "gatsby";
import Logo from "../shared/Logo"
import frenchConnection from "../../images/svgs/FC-STACK-CENTRED-THIN-LOGO.svg"
import { Container } from 'reactstrap';

const Wrapper = styled.div`
    position: relative;
    overflow: hidden;
    height: 50vh;
    max-height:  450px;
    min-height: 200px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    .gatsby-image-wrapper {
        max-width: 358px;
        width: 70%;
    }
    
    .helper {
        width: 450px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 auto;
    }
    
    &.grey {
        background-color: #ECECEC;
    }
`



class PageHeader extends Component {
    render() {
        if (this.props.tour) {
            return(
                <Container fluid className={this.props.bg}>
                    <GatsbyImage image={getImage(this.props.data.headerTour)}
                        alt=""
                        title=""
                        className='d-none d-md-block'
                    />
                    <GatsbyImage image={getImage(this.props.data.headerTourMobile)}
                        alt=""
                        title=""
                        className='d-md-none'
                    />
                </Container>
            )
        } else {
            return (
                <Wrapper className={this.props.bg}>
                  {this.props.logo ?
                    <div className="helper">
                    <GatsbyImage image={getImage(this.props.logo)}
                                 alt=""
                                 title=""
                    />
                        <img src={frenchConnection}/>
                    </div>
                  :
                    <GatsbyImage image={getImage(this.props.data.logo)}
                                 alt=""
                                 title=""
                    />
                  }
                    
                </Wrapper>
            );
        }

    }
}

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                logo: file(relativePath: { eq: "grease-big-logo.png" }) {
                    childImageSharp {
                         gatsbyImageData(width: 1000, quality: 100, placeholder: NONE)
                    }
                }
                headerTour: file(relativePath: { eq: "Grease_Generic_1920x780.jpg" }) {
                    childImageSharp {
                         gatsbyImageData(width: 1920, quality: 100, placeholder: NONE)
                    }
                }
                headerTourMobile: file(relativePath: { eq: "Grease_Generic_750x675.jpg" }) {
                    childImageSharp {
                         gatsbyImageData(width: 768, quality: 100, placeholder: NONE)
                    }
                }
            }
		`}
        render={data => (
            <PageHeader {...props} data={data}/>
        )}
    />
)
export default Query

PageHeader.defaultProps = {
    tour: false
}