import React, {Component} from 'react';
import {Modal, ModalBody} from "reactstrap"
import styled from "styled-components"
import {media} from "utils/Media"
import {GatsbyImage} from "gatsby-plugin-image";


const StyledModal = styled(Modal)`
       width: 90%!important;
    max-width: 1000px!important;
    margin: 3rem auto 0 auto!important;
`

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 0 1rem;
`

const ImageWrapper = styled.div`
    width: 100%;
    order: 2;
    overflow: hidden;
    text-align: center;
    
    @media ${media.lg} {
        width: 50%;
        order: 1;
    }
   
   img {
        border: 5px solid ${props => props.theme.colors.hotPink};
   }
`

const Content = styled.div`
    position: relative;
    z-index: 2;
    text-align: center;
    padding: 0 1rem;
    order: 1;
    width: 100%;
    margin-bottom: 2rem;
    
    @media ${media.md} {
        padding: 0 2rem;
    }
    
    @media ${media.lg} {
        width: 50%;
        order: 2;
        margin: 0 auto;
    }
    
    h4 {
        color: ${props => props.theme.colors.secondary} !important;
        font-family: ${props => props.theme.font.family.boldTall} !important;
        text-transform: uppercase;
        text-align: left;
        
        &:nth-of-type(2) {
           color: ${props => props.theme.colors.primary} !important;
        }
    }
    
    p {
        color: ${props => props.theme.colors.black};
        text-align: left;
        font-size: 1.1rem!important;
    }
`

const ButtonClose = styled.button`
	position: absolute;
    background-color: ${props => props.theme.colors.black};
    border: 0;
    z-index: 10;
    right: 0;
    top: 0;
    width: 32px;
    height: 32px;

    @media ${media.lg} {
        top: -1rem;
        width: 48px;
        height: 48px;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 75%;
        height: 2px;
        background-color: #fff;
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(45deg);
    }
`

class JasonModal extends Component {
    render() {
        return (
            <StyledModal isOpen={this.props.isOpen} className="covid-modal" toggle={this.props.toggle}
                         centered={true}>
                <ModalBody>
                    <ButtonClose onClick={this.props.toggle}>
                        <span className="sr-only">Close</span>
                    </ButtonClose>
                    <Wrapper>
                        <ImageWrapper>
                            <GatsbyImage
                                image={this.props.img}
                                alt="Jason Donovan | Grease The Musical"
                                className="mb-2"
                            />
                        </ImageWrapper>
                        
                        <Content>
                            <h4 className="mb-0">Jason Donovan</h4>
                            <h4>Teen Angel</h4>

                        <p>Jason Donovan came into our lives in the late 80s as Scott Robinson in the hit Australian series, &lsquo;Neighbours&rsquo;. Throughout 1988/89 he had several hits and his album &lsquo;Ten Good Reasons&rsquo; was the best-selling album of 1989. His hits included &lsquo;Too Many Broken Hearts&rsquo;, &lsquo;Especially For You&rsquo; (with Kylie Minogue), &lsquo;Any Dream Will Do&rsquo;, &lsquo;Sealed With A Kiss&rsquo;, &lsquo;When You Come Back to Me&rsquo;, &lsquo;Everyday&rsquo;, &ldquo;Nothing Can Divide Us&rsquo; and many more. Since then, he has rarely been away from stage or screen. Jason&rsquo;s record breaking run in &lsquo;Joseph&rsquo; in the early 90s preceded &lsquo;The Rocky Horror Show&rsquo;, &lsquo;Chitty Chitty Bang Bang&rsquo;, &lsquo;Priscilla&rsquo;, and &lsquo;The Sound of Music&rsquo;, &lsquo;Annie Get Your Gun&rsquo;, &lsquo;The King&rsquo;s Speech&rsquo;, &lsquo;Million Dollar Quartet&rsquo;, Jeff Wayne&rsquo;s &lsquo;The War Of The Worlds&rsquo; and &lsquo;Chicago&rsquo;. In Autumn 2021, Jason undertook his first solo music concert in five years with his &lsquo;Even More Good Reasons&rsquo; Tour which he performed whilst also filming the BBC hit show &lsquo;Not Going Out&rsquo;. Jason&rsquo;s recent TV credits also include on ITV&rsquo;s &lsquo;Dial M for Middlesbrough&rsquo; and Dave&rsquo;s &lsquo;Meet the Richardson&rsquo;s&rsquo;.</p>
                        <p>In 2019 and 2021, Jason spent the summer months at the London Palladium as Pharaoh in Joseph and his Amazing Technicolor Dreamcoat and is delighted to be returning this year, taking the show to venues throughout the UK.</p>
                            
                        </Content>
                    </Wrapper>
                    
                </ModalBody>
            </StyledModal>
        );
    }
}

export default JasonModal;