import React, {Component} from 'react';
import {Modal, ModalBody} from "reactstrap"
import styled from "styled-components"
import {media} from "utils/Media"
import {GatsbyImage} from "gatsby-plugin-image";

const StyledModal = styled(Modal)`
    /* max-width: calc(100% - 1rem); */
    width: 90%!important;
    max-width: 1000px!important;
    margin: 3rem auto 0 auto!important;

    .modal-content {
        background: white;
    }
`

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 0 1rem;
`

const ImageWrapper = styled.div`
    width: 100%;
    order: 2;
    overflow: hidden;
    text-align: center;
    
    @media ${media.lg} {
        width: 50%;
        order: 1;
    }
   
   img {
        border: 5px solid ${props => props.theme.colors.hotPink};
   }
`

const Content = styled.div`
    position: relative;
    z-index: 2;
    text-align: center;
    padding: 0 1rem;
    order: 1;
    width: 100%;
    margin-bottom: 2rem;
    
    @media ${media.md} {
        padding: 0 2rem;
    }
    
    @media ${media.lg} {
        width: 50%;
        order: 2;
        margin: 0 auto;
    }
    
    h4 {
        color: ${props => props.theme.colors.secondary} !important;
        font-family: ${props => props.theme.font.family.boldTall} !important;
        text-transform: uppercase;
        text-align: left;
        
        &:nth-of-type(2) {
           color: ${props => props.theme.colors.primary} !important;
        }
    }
    
    p, a  {
        color: ${props => props.theme.colors.black};
        text-align: left;
        font-size: 1.1rem!important;
        text-decoration: none;
        
    }
    a:hover, a:active {

        text-decoration: underline 2px ${props => props.theme.colors.secondary}!important;
    }
    a {
        font-size: 1.1rem!important;
    }
`

const ButtonClose = styled.button`
	position: absolute;
    background-color: ${props => props.theme.colors.black};
    border: 0;
    z-index: 10;
    right: 0;
    top: 0;
    width: 32px;
    height: 32px;

    @media ${media.lg} {
        top: -1rem;
        width: 48px;
        height: 48px;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 75%;
        height: 2px;
        background-color: #fff;
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(45deg);
    }
`

class CastModal extends Component {
    render() {
        return (
            <StyledModal isOpen={this.props.isOpen} className="covid-modal" toggle={this.props.toggle}
                         centered={true}>
                <ModalBody>
                    <ButtonClose onClick={this.props.toggle}>
                        <span className="sr-only">Close</span>
                    </ButtonClose>
                    {this.props.person &&
                    <Wrapper>
                        {this.props.person.image &&
                        <ImageWrapper>
                            <GatsbyImage
                                image={this.props.person.image.childImageSharp.gatsbyImageData}
                                alt={this.props.person.name}
                                title={this.props.person.name}
                                className="mb-2"
                            />
                        </ImageWrapper>
                        }
                        <Content className={`${!this.props.person.image && "w-100"}`}>
                            <h4 className="mb-0">{this.props.person.name}</h4>
                            {/* <h4>{this.props.person.role}</h4> */}
                            <h4 dangerouslySetInnerHTML={{__html: this.props.person.role}} />
                            <div dangerouslySetInnerHTML={{__html: this.props.person.bio}}/>
                        </Content>
                    </Wrapper>
                    }
                </ModalBody>
            </StyledModal>
        );
    }
}

export default CastModal;